<template>
   <div>
      <div class="mb-3">
         <AmcText v-if="this.Alarm && Text" Color="#ea3d2f" class="fw-500 me-5" inline>
            {{ this.Alarm }} Alarm
         </AmcText>
         <AmcText v-if="this.Fault && Text" Color="#f0d961" class="fw-500 me-5" inline>
            {{ this.Fault }} Arıza
         </AmcText>
         <AmcText v-if="this.Info && Text" Color="#f0d961" class="fw-500 me-5" inline> {{ this.Info }} Info </AmcText>
         <AmcText v-if="this.Fault && Text" Color="#f0d961" class="fw-500 me-5" inline>
            {{ this.Fault }} Arıza
         </AmcText>
         <AmcText v-if="this.Online && Text" Color="#2FA84F" class="fw-500 me-5" inline>
            {{ this.Online }} Online
         </AmcText>
         <AmcText v-if="this.Offline && Text" Color="#DCDFF2" class="fw-500 me-5" inline>
            {{ this.Offline }} Offline
         </AmcText>
      </div>
      <div class="state-bar d-flex flex-row">
         <div
            :class="{ alarm: Alarm }"
            :style="{
               width: `${Number(this.Alarm) * this.topla()}` + '%',
            }"
            class="state"
         ></div>
         <div
            :class="{ fault: Fault }"
            :style="{
               width: `${Number(this.Fault) * this.topla()}` + '%',
            }"
            class="state"
         ></div>
         <div
            :class="{ offline: Offline }"
            :style="{
               width: `${Number(this.Offline) * this.topla()}` + '%',
            }"
            class="state"
         ></div>
         <div
            :class="{ online: Online }"
            :style="{
               width: `${Number(this.Online) * this.topla()}` + '%',
            }"
            class="state"
         ></div>
      </div>
   </div>
</template>
<script>
export default {
   name: 'AmcStateBar',
   data() {
      return {};
   },
   props: {
      Alarm: {
         type: [Boolean, String],
         default: null,
      },
      Fault: {
         type: [Boolean, String],
         default: null,
      },
      Offline: {
         type: [Boolean, String],
         default: null,
      },
      Online: {
         type: [Boolean, String],
         default: null,
      },
      Text: {
         type: Boolean,
         default: false,
      },
   },
   methods: {
      topla() {
         let sayi1 = Number(this.Alarm);
         let sayi2 = Number(this.Fault);
         let sayi3 = Number(this.Offline);
         let sayi4 = Number(this.Online);
         return 100 / (sayi1 + sayi2 + sayi3 + sayi4);
      },
   },
};
</script>

<style lang="scss" scoped>
.state-bar {
   height: 8px;
   width: content-box;
   border-radius: 100px;
   overflow: hidden;

   & .alarm {
      height: 8px;
      background-color: #ea3d2f;
   }

   & .fault {
      height: 8px;
      background-color: #f0d961;
   }

   & .offline {
      height: 8px;
      background-color: #f0f1f2;
   }

   & .online {
      height: 8px;
      background-color: #2fa84f;
   }

   & .info {
      height: 8px;
      background-color: #2fa84f;
   }
}
</style>
